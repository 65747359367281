import React, { createRef, FunctionComponent, useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Button from "react-bootstrap/Button";
import { WhereToBuyInterface } from "../WhereToBuy";
import { event38 } from "../../analytics/event38";
import { event37 } from "../../analytics/event37";
import { LocalizedContext } from "../../services/LocalizedContextService";
import "./index.scss";

type MikMakInterface = WhereToBuyInterface;

const MikMak: FunctionComponent<MikMakInterface> = ({ smartProductId, productName, productCategoryName, brand }) => {
  const { buyItNow } = useContext(LocalizedContext).sanityLabels?.productLabels || {};
  const psButton = createRef<HTMLButtonElement>();
  const visibleButton = createRef<HTMLButtonElement>();
  const [shouldMikMakLoad, setShouldMikMakLoad] = useState(false);
  const [existingScript, setExistingScript] = useState(false);
  const onClick = (e: any) => {
    e.preventDefault();
    psButton.current?.click();
    event38(smartProductId, productName, productCategoryName, brand);
  };
  const loadMikMak = () => {
    if (!shouldMikMakLoad) {
      setShouldMikMakLoad(true);
    }
  };
  function analyticsMikmak() {
    // push MikMak event to GTM
    const myCallback = (e: { action: string }) => {
      if (e.action === "buy") {
        event37(e);
      }

      if (e.action === "close") {
        window.history.replaceState(null, "", window.location.pathname);
      }
    };
    // set the tracking method in the MikMak data layer
    // @ts-ignore
    if (!window.swnDataLayer) window.swnDataLayer = {};
    // @ts-ignore
    window.swnDataLayer.widget_callback = myCallback;
  }
  useEffect(() => {
    const scriptId = "mikmak-script";
    setExistingScript(document.getElementById(scriptId) !== null);
    loadMikMak();
    analyticsMikmak();
  }, [shouldMikMakLoad]);

  useEffect(() => {
    const params = window.location.search;
    const decodedParams = new URLSearchParams(params);

    if (window.location.pathname.includes(productName.toLowerCase().replace(/\s+/g, "-"))) {
      setTimeout(() => {
        for (const [key, value] of decodedParams.entries()) {
          if (key === "mikmak" && value === "true") {
            if (
              smartProductId &&
              psButton.current &&
              visibleButton.current &&
              window.location.pathname.includes(productName.toLowerCase().replace(/\s+/g, "-"))
            ) {
              visibleButton.current.click();
            }
          }
        }
      }, 5000);
    }
  }, [psButton]);

  return (
    <div className="mikMak">
      <Button
        ref={visibleButton}
        ps-sku={smartProductId}
        data-eans={smartProductId}
        className="buy-it-now-button swn-tag-wtb-btn"
        onClick={onClick}
      >
        {buyItNow}
      </Button>
      <button
        ref={psButton}
        aria-label="where to buy product search"
        className="ps-widget mikMak-button"
        ps-sku={smartProductId}
      />
    </div>
  );
};

export default MikMak;
