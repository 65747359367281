import React from "react";
import "./styles.scss";

const IsNewProduct = ({ text }) => {
  return (
    <div className="new-product">
      <span className="new-badge">{text}</span>
    </div>
  );
};

export default IsNewProduct;