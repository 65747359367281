import React, { FunctionComponent, useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { navigate } from "gatsby";
import Picture from "../Picture";
import { Link } from "../Link";
import { ImageInterface } from "../../types/SanityTypes";
import ProductRatings from "../ProductRatings";
import WhereToBuy from "../WhereToBuy";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { RightTriangleArrow } from "../../images/icons/triangleRight";
import IsNewProduct from "../IsNewProduct";

import "./styles.scss";
import MikMak from "../MikMak";

export interface ProductCardInterface {
  title: string;
  description?: string;
  img: ImageInterface;
  link: string;
  paUPC: string;
  paSmartProductId: string;
  paRetailerCategory: string;
  inView: boolean;
  isNew: boolean;
  isBestSeller: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  preferPerformance?: boolean;
}

const ProductCard: FunctionComponent<ProductCardInterface> = ({
  img,
  title,
  link,
  paSmartProductId,
  paUPC,
  paRetailerCategory,
  inView,
  isNew,
  isBestSeller,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  preferPerformance = false
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);
  const placeholder =
    "https://cdn.sanity.io/images/4nopozul/tresemme-staging-us/d7d1a017a5d9ebcb7f7b75691c3b900973121772-1600x1600.jpg";
  const productCardImage = {
    src: `${img?.asset?.url || placeholder}?w=384&h=250&fit=fill&auto=format&q=80&bg=fff`,
    alt: `${img?.alt || title}`,
    sources: [
      {
        srcset: [
          `${img?.asset?.url || placeholder}?w=384&h=330&fit=fill&auto=format&q=80&bg=fff 1x`,
          `${img?.asset?.url || placeholder}?w=768&h=660&fit=fill&auto=format&q=80&bg=fff 2x`
        ],
        media: `screen and (max-width: 767px)`
      },
      {
        srcset: [
          `${img?.asset?.url || placeholder}?w=384&h=330&fit=fill&auto=format&q=80&bg=fff 1x`,
          `${img?.asset?.url || placeholder}?w=768&h=660&fit=fill&auto=format&q=80&bg=fff 2x`
        ],
        media: `screen and (min-width: 768px)`
      }
    ],
    maintainAspectRatio: true,
    size: {
      width: 380,
      height: 300
    },
    lqip: img?.asset?.metadata?.lqip
  };
  const { isNewProduct } = useContext(LocalizedContext).sanityLabels?.productLabels || {};
  const { isBestSellerProduct } = useContext(LocalizedContext).sanityLabels?.productLabels || {};

  const [ref, ratingInView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  const handleNavigate = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${link}/`);
    }
  };

  return (
    <Link
      to={link}
      className="h-100 underline-none"
      onClick={onClick}
      data-analytics-custombutton
      aria-label={`Find out more ${title}`}
      tabIndex={-1}
    >
      <article className="product-card card rounded-0 d-flex justify-content-between h-100">
        <Picture
          src={productCardImage.src}
          alt={productCardImage.alt}
          className="card-img-top"
          sources={productCardImage.sources}
          size={productCardImage.size}
          maintainAspectRatio={productCardImage.maintainAspectRatio}
          lqip={productCardImage.lqip}
          loading={preferPerformance ? "eager" : "lazy"}
          inView={inView}
        />

        <div className="product-card__wrapper" ref={ref}>
          {ratingInView && (
            <>
              <span className="product-card__title card-title  keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>
                {title}
              </span>
              <span className="product-card__category keyboard-focus">{paRetailerCategory}</span>
              <div>
                {(paSmartProductId || paUPC) && (
                  <div className="ratings keyboard-focus" tabIndex={0} onKeyDown={handleNavigate}>
                    <ProductRatings
                      upc={paUPC || paSmartProductId}
                      productName={title}
                      summary={false}
                      template="none"
                    />
                  </div>
                )}
                <p onClick={e => e.preventDefault()} className="buynow">
                  <MikMak
                    smartProductId={paSmartProductId || paUPC}
                    productName={title}
                    productCategoryName={paRetailerCategory || ""}
                    brand={sanitySiteSettings.brandName}
                  />
                </p>
                <div className="cta-link" role="link">
                  Find out more <RightTriangleArrow />
                </div>
              </div>
            </>
          )}
        </div>
        {isNew && <IsNewProduct text={isNewProduct} />}
        {isBestSeller && <IsNewProduct text={isBestSellerProduct} />}
      </article>
    </Link>
  );
};

export default ProductCard;
