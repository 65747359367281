/* eslint-disable no-console */
import { logEvent } from "./log-events";

export const event37 = e => {
  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.retailerClick,
    eventLabel: `Online - ${e.product.name}`,
    eventValue: 1
  };

  analyticsEvent.category = { primaryCategory: window.ctConstants.custom };

  window.digitalData.product = [];
  window.digitalData.product.push({
    productInfo: {
      productID: e.product.ean,
      productName: e.product.name
    },
    category: {
      primaryCategory: "PRODUCT CATEGORY"
    },
    attributes: {
      productVariants: "",
      listPosition: ""
    }
  });

  logEvent("Retailer Click ++++", analyticsEvent);
  window.digitalData.event.push(analyticsEvent);
};
